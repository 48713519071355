.login-parent{
    height: 100vh;
}
.login-wrapper{
    width: 100%;
    display: flex;
    height: 100%;
    overflow: hidden;
}

.auth-left, .auth-right{
    width: 50%;
    height: 100%;
}

.auth-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.auth-right h1{
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    color: #5C5C5C;
}

.login-form{
    width: 70%;
    margin-left: 15%;
}

.bin-logo-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: 50px;
}

.bin-logo{
    width: 100px;
    height: 30px;
}

.login-form label{
    font-weight: 600;
    font-size: 24px;
    color: #1732A4;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
}

.login-form label input{
    height: 40px;
    border-radius: 5px;
    color: #1732A4;
    padding-left: 20px;
    margin-top: 10px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: #f0ebeb;
    border: 1px solid #5C5C5C;
}


.login-form label input::placeholder{
    font-weight: 600;
    color: #8b8b8b;
}

.login-btn{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-btn button{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
}

.mobile-login{
    display: none;
}

@media (max-width: 900px) {
    .login-btn{
        width: 80px;
        height: 25px;
    }
    .auth-right h1{
        font-size: 18px;
    }
    .login-form label{
        font-size: 16px;
        margin-bottom: 50px;
        width: 100%;
    }
    
    .login-form label input{
        height: 40px;
        border-radius: 5px;
        padding-left: 20px;
        margin-top: 10px;
        font-size: 13px;
    }
    .login-btn button{
        border-radius: 5px;
        padding: 15px 30px;
        font-size: 13px;
    }
}
@media (max-width: 600px) {
    .login-wrapper{
        background-color: #5C5C5C;
        display: none;
    }
    .mobile-login{
        display: flex;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .auth-bg{
        width: 100%;
        margin-right: 0;
        position: fixed;
    }
    .bin-logo-div{
        margin-bottom: 30px;
    }
    .bin-logo{
        width: 80px;
        height: 25px;
    }
    .auth-right{
        padding-top: 20px;
        background-color: #fff;
        border-radius: 10px;
    }
    .login-btn{
        width: 100px;
        height: 25px;
    }
    .auth-right h1{
        font-size: 18px;
    }
    .auth-right{
        z-index: 99;
        background-color: white;
        margin-left: 25%;
        height: 80%;
    }
    .login-form label{
        font-size: 13px;
        margin-bottom: 30px;
        width: 100%;
    }
    
    .login-form label input{
        height: 40px;
        border-radius: 5px;
        padding-left: 20px;
        margin-top: 10px;
        font-size: 11px;
    }
    .login-btn button{
        border-radius: 5px;
        padding: 15px 30px;
        font-size: 11px;
    }
    .mobile-footer p{
        font-size: 9px;
    }
    .mobile-footer{
        margin-top: -50px;
        justify-content: center;
        padding-right: 0;
    }

}
@media (max-width: 480px) {
    .bin-logo-div{
        margin-bottom: 30px;
    }
    .bin-logo{
        width: 80px;
        height: 25px;
    }
    .login-btn{
        width: 100px;
        height: 25px;
    }
    .auth-right h1{
        font-size: 16px;
    }
    .auth-right{
        padding-top: 20px;
        height: 70%;
    }
    .login-form label{
        font-size: 11px;
        margin-bottom: 20px;
        width: 100%;
    }
    
    .login-form label input{
        height: 30px;
        border-radius: 5px;
        padding-left: 20px;
        margin-top: 10px;
        font-size: 9px;
    }
    .login-btn button{
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 10px;
        margin-top: 5px;
    }
}
@media (max-width: 400px) {
    .mobile-login{
        align-items: flex-start;
    }
    .bin-logo-div{
        margin-bottom: 30px;
    }
    .bin-logo{
        width: 70px;
        height: 20px;
        margin-top: 5px;
    }
    .login-btn{
        width: 80px;
        height: 20px;
    }
    .auth-right h1{
        font-size: 14px;
    }
    .auth-right{
        padding-top: 10px;
        margin-top: 40px;
        height: 60%;
    }
    .login-form label{
        font-size: 10px;
        margin-bottom: 20px;
        width: 100%;
    }
    
    .login-form label input{
        height: 25px;
        border-radius: 5px;
        padding-left: 10px;
        margin-top: 5px;
        font-size: 8px;
    }
    .login-btn button{
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 9px;
        margin-top: 5px;
    }
}