.home-parent-div{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.home-heading h2{
    font-size: 40px;
    font-weight: 600;
}
.home-heading span{
    font-size: 28px;
    font-weight: 500;
    margin-left: 10px;
}
.gauge-wrapper{
    width: 30%;
}

.primary-btn{
    background: linear-gradient(#183EA8, #1EA2C6);
    margin-top: 20px;
}
.data-analytics-div {
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
  }
  .avg-left{
    width: 60%;
  }
  .alert-right{
    width: 30%;
    position: fixed;
    margin-left: 50%;
  }
  .nig-banner-img{
    height: 120px;
    width: 130px;
  }
  .banner-img{
    height: 80px;
    width: 160px;
  }
  .powered{
    font-size: 16px;
    margin-bottom: 10px;
  }
  .home-subheading{
    font-size: 30px;
  }

@media (max-width: 600px) {
    .home-parent-div{
        flex-direction: column;
    }
    .avg-left{
        width: 100%;
      }
      .alert-right{
        width: 100%;
        position: relative;
        margin-left: 0;
      }
    .next-section{
        flex-direction: column;
    }
    .analytics, .overview{
        width: 100%;
    }
    .overview{
        width: 100%;
    }
    .data-analytics-div {
        width: 100%;
        flex-direction: column;
      }
      .home-heading h2{
        font-size: 18px;
        font-weight: 600;
    }
    .home-heading span{
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
    }
      .nig-banner-img{
        height: 80px;
        width: 90px;
      }
      .banner-img{
        height: 40px;
        width: 80px;
      }
      .powered{
        font-size: 10px;
        margin-bottom: 10px;
      }
      .home-subheading{
        font-size: 20px;
        text-align: center;
      }
      .slider-arrow{
        display: none;
      }
      .gauge-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 400px) {
    .home-heading h2{
        font-size: 16px;
    }
    .home-heading span{
        font-size: 10px;
    }
}