.bin-details-wrapper{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.418);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 111;
}
.save-btn:hover{
    background-color: #05222f;
}
.bin-details{
    box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.50);
    border-radius: 12px;
    background-color: #FFF;
    margin-bottom: 40px;
    padding: 30px;
    padding-top: 10px;
    width: 60%;
}
.bin-details-heading{
    color: #5C5C5C;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.bin-reg-form{
    display: grid;
    grid-template-columns:  1fr 1fr;
}
.bin-reg-form label{
    display: flex;
    flex-direction: column;
    color: #272727;
    font-size: 14px;
    font-style: normal;
    margin-bottom: 15px;
    font-weight: 600;
}
.bin-reg-form label input, #select-field{
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    outline: none;
    border: none;
    background: rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    height: 40px;
    width: 90%;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 10px;
}
.bin-reg-form label input::placeholder{
    color: #B5B5B5;
}
#select-field{
    width: 96%;
}
.save-cancel-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.save-btn, .cancel-btn{
    border-radius: 5px;
    background-color: #183EA8;
    color: #FFF;
    border: none;
    margin-right: 10px;
    width: 105px;
    height: 40px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
}
.cancel-btn{
    border: 1px solid #183EA8;
    color: #183EA8;
    background-color: #FFF;
}
.cancel-btn:hover{
    border: 1px solid #05222f;
    color: #05222f;
    background-color: #F8F9FA;
}
#disabled-btn{
    background-color: #4c5154;
}

@media (max-width: 650px) {
    .bin-reg-form{
        display: grid;
        grid-template-columns:  1fr;
    }
    .save-cancel-btn{
        margin-top: 25px;
    }
    .save-btn, .cancel-btn{
        font-size: 10px;
        height: 30px;
        width: 80px;
    }
    .bin-details-heading{
        font-size: 14px;
    }
    .bin-reg-form label{
        font-size: 11px;
    }
    .bin-reg-form label input, #select-field{
        font-size: 11px;
        height: 30px;
        margin-top: 5px;
    }
        
}