@media (max-width: 600px) {
    .home-card{
        width: 120px;
        height: 80px;
    }
    .img-wrap{
        width: 20px;
        height: 20px;
        padding: 5px;
    }
    .home-card img{
        width: 14px;
        height: 14px;
    }
    .first-T{
        font-size: 10px;
    }
    .second-T{
        font-size: 8px;
    }
}
@media (max-width: 428px) {
    .home-card{
        width: 100%;
        height: auto;
    }
    .img-wrap{
        width: 20px;
        height: 16px;
        padding: 5px;
    }
    .home-card img{
        width: 14px;
        height: 10px;
    }
    .first-T{
        font-size: 8px;
    }
    .second-T{
        font-size: 5px;
    }
}