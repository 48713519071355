.sidebar-wrapper {
  display: flex;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}
#sider{
  position: sticky;
}
#content-layout, #expand-content{
  position: relative;
}
#content-header, #expand-header{
  position: sticky;
}
.sidebar-con {
  width: 95%;
  background-color: white;
  box-shadow: 1px 1px 1px 1px lightgrey;
  height: 100vh;
  position: fixed;
  z-index: 110;
  display: flex;
  justify-content: space-between;
}

.side-logo {
  width: 80px;
  height: 25px;
}
#smallImg {
  width: 50px;
  height: 20px;
}
.sidebar-icon {
  width: 32px;
  margin-right: 10px;
  height: auto;
  margin-top: 10px;
}
.sidebar-line {
  height: 100vh;
  background-color: #a56c03;
  width: 2px;
}
.sidebar-section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.side-section-1-text {
  margin-top: 20px;
  font-weight: 800;
  font-size: 14px;
  color: #304abc !important;
}
/* .side-section-1-text.active {
  color: #1732A4;
} */

.sidebar-section-2 {
  margin: 5px;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sandbox-text {
  font-weight: 600;
  margin-top: 19px;
}
.sandbox-icon {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 12px;
}


.children {
  margin-left: 9%;
  width: 90%;
}
.ant-menu-item:hover {
  color: #1732A4 !important;
}

.ant-menu-item:hover {
  color: #1732A4 !important;
}
.active-link {
  color: #1732A4 !important;
}
.ant-menu-item {
  color: #677fe8 !important;
  font-weight: 500 !important;
}
.ant-menu-item-selected {
  color: #1732A4 !important;
  font-weight: 800 !important;
  background-color: transparent !important;
}
#centralisedIcon {
  margin-left: 60px;
}
#close-menu{
  display: none;
}
.logo-textt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-textt .logo-t{
  width: 90%;
  line-height: 30px;
  text-align: center;
  font-size: 18px;
}
#small-logo{
  font-size: 10px;
}

@media (max-width: 578px) {
  .side-logo {
    width: 60px;
    height: 60px;
  }
  #sider{
    z-index: 111;
  }
  #content-header{
    margin-left: -200px !important;
  }
  #expand-content{
    margin-left: -200px !important;
  }
  #close-div{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: -10px;
    margin-right: 50px;
    color: black;
  }
  #close-menu{
    display: flex;
    font-size: 24px;
    margin-right: 10px;
  }
}
